<template>
  <div class="campaign-story-skeleton">
    <app-preview-card :height="height" size="medium" :width="width">
      <template v-slot:background>
        <skeleton-box class="campaign-background" />
      </template>
    </app-preview-card>
  </div>
</template>

<script>
import AppPreviewCard from '@/components/AppPreviewCard';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  components: { AppPreviewCard, SkeletonBox },
  props: {
    width: {
      type: String,
      default: '130'
    },
    height: {
      type: String,
      default: '200'
    }
  }
};
</script>

<style lang="scss" scoped>
.campaign-story-skeleton {
  pointer-events: none;

  .campaign-background {
    height: 100%;
  }
}
</style>
