<template>
  <div class="app-glide tw-relative">
    <flickity
      :key="loading"
      ref="flickity"
      v-scrollable:x
      :options="flickityOptions"
      class="app-glide__flickity">
      <slot v-for="(item, index) in items" :index="index" :item="item" />
    </flickity>
    <app-button v-if="customPrevNextButtons" state="text" class="flickity-button flickity-prev-next-button previous" @click="previous()">
      <chevron-previous-circle-icon class="tw-w-layout-3 tw-h-layout-3 tw-text-jb-grey-400 tw-fill-white hover:tw-fill-jb-grey-50 tw-transition" />
    </app-button>
    <app-button v-if="customPrevNextButtons" state="text" class="flickity-button flickity-prev-next-button next" @click="next()">
      <chevron-next-circle-icon class="tw-w-layout-3 tw-h-layout-3 tw-text-jb-grey-400 tw-fill-white hover:tw-fill-jb-grey-50 tw-transition" />
    </app-button>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import ChevronPreviousCircleIcon from '@/assets/svg/chevron-previous-circle-icon.svg';
import ChevronNextCircleIcon from '@/assets/svg/chevron-next-circle-icon.svg';
import AppButton from '@/components/AppButton';
export default {
  name: 'AppGlide',
  components: { Flickity, ChevronPreviousCircleIcon, ChevronNextCircleIcon, AppButton },
  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    customPrevNextButtons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    flickityOptions() {
      return {
        contain: this.loading,
        freeScroll: !this.loading,
        draggable: !this.loading,
        pageDots: !this.loading,
        prevNextButtons: !this.loading && !this.customPrevNextButtons,
        autoPlay: false,
        wrapAround: false,
        percentPosition: false,
        cellAlign: 'left'
      };
    }
  },
  watch: {
    loading: {
      handler(loading) {
        if (!loading) {
          this.$nextTick(() => {
            this.$refs.flickity.on('staticClick', (event, pointer, cellElement, cellIndex) => {
              this.items[cellIndex] && this.$emit('item-click', this.items[cellIndex]);
            });
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    }
  }
};
</script>
<style lang="scss">
.app-glide {
  &__flickity {
    display: flex;
  }

  .flickity-viewport {
    @apply tw-w-full;
  }

  .flickity-prev-next-button {
    @apply tw-hidden tw-bg-none tw--translate-y-1/2 sm:tw-flex md:tw-bg-transparent focus:tw-shadow-none;

    &:hover:not(:disabled) {
      @apply tw--translate-y-1/2;
    }

    &.next {
      @apply tw--right-layout-2;
    }

    &.previous {
      @apply tw--left-layout-2;
    }
  }

  .flickity-page-dots {
    @include sm-up {
      display: none;
    }

    .dot {
      transition: all 0.3s ease;

      @apply tw-bg-jb-grey-400 tw-mx-space-1;

      &.is-selected {
        @apply tw-bg-jb-indigo;
      }
    }
  }
}
</style>
